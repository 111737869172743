




































































































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { IVenue } from '@einfachgast/shared';
import { RouteNames } from '@/router';
import { QrCodePrintService } from '@/models/qr-code-print-service';
import { IQrCode } from '@/interfaces/i-qr-code';
import { QrCode } from '@/models/qr-code';
import { DBCollections, firebaseWrapper as fb } from '@/firebase-wrapper';
import firebase from 'firebase/app';
import { plainToClass } from 'class-transformer';
import { QrCodePrintTypes } from '@/models/qr-code-print-types';
import VenueQrCodePrintLangSelect from '@/components/venues/venue-qr-code-print-lang-select.vue';
import VenueStartSetupTablet from '@/components/venues/venue-steps/venue-start/venue-start-setup-tablet.vue';
import { createQrCodeForArea } from '@/helpers/qr-code-creation-helper';
import AdminArea from '@/models/admin-area';
import { DocumentData, collection, getDocs, query, where } from 'firebase/firestore';

@Component({
  name: 'VenueStartStep',
  components: {
    VenueStartSetupTablet,
  },
})
export default class VenueStartStep extends Vue {
  qrCodePrintService: QrCodePrintService = null;
  loading = false;
  setupTableModalVisible = false;
  qrCodes: IQrCode[] = [];

  @Prop()
  venue: IVenue;

  get routeNames () {
    return RouteNames;
  }

  get isMultiLanguage () {
    return this.languageSettings && this.languageSettings.length > 1;
  }
  get languageSettings () {
    return this.$auth.venueOwner.settings.activeLanguages;
  }

  get adminQrCode () {
    return this.qrCodePrintService?.qrCodes.find(x => x.isAdminCode === true);
  }

  setupTablet() {
    // how setup tablet modal
    this.setupTableModalVisible = true;
  }

  closeStartSetupTablet() {
    this.setupTableModalVisible = false;
  }
  async mounted() {
    await this.fetchQrCode();
    if (!this.adminQrCode) {
      await createQrCodeForArea(this.venue, new AdminArea(), QrCodePrintTypes.Admin);
    }
    // to set isDirty to false
    this.$emit('areaSaved');
  }

  async fetchQrCode() {
    if (this.venue.id) {
      this.loading = true;
      const querySnapshot = await getDocs(query(collection(fb.db, DBCollections.QrCodes), where('venueId', '==', this.venue.id)));
      querySnapshot.forEach((doc: DocumentData) => {
        const qrCodeData = doc.data() as IQrCode;
        qrCodeData.id = doc.id;
        this.qrCodes.push(plainToClass(QrCode, qrCodeData));
      });
      this.loading = false;
    }
    this.qrCodePrintService = new QrCodePrintService(this.venue, this.$stripe.packageScope, this.qrCodes, this);
  }

  async printQrCode() {
    this.loading = true;

    if (!this.isMultiLanguage) {
      await this.printByLang(this.languageSettings[0] || 'de');
      this.loading = false;
      return;
    }
    const ComponentClass = Vue.extend(VenueQrCodePrintLangSelect);
    const instance = new ComponentClass();
    instance.$on('printQrCode', async (selectedLang: string) => {
      (this.$refs['qr-code-lang-select-container'] as HTMLElement).removeChild(instance.$el);
      instance.$destroy();
      await this.printByLang(selectedLang);
    });
    instance.$on('close', () => {
      (this.$refs['qr-code-lang-select-container'] as HTMLElement).removeChild(instance.$el);
      instance.$destroy();
    });
    instance.$mount();
    (this.$refs['qr-code-lang-select-container'] as HTMLElement).appendChild(instance.$el);
    this.loading = false;
  }

  async printByLang (lang: string) {
    await this.qrCodePrintService.prepareQrCodeHtmlAndPrint(this.venue.areas.filter(x => x.isDeactivated === false), QrCodePrintTypes.All, lang);
  }

  async navigateTo (routeName: string) {
    await this.$router.push({ name: routeName });
  }

  goToDesignStep () {
    void this.$router.push({ name: RouteNames.VenueEditTabDesign, params: { venueId: this.venue.id } });
  }

  goToDocumentsStep () {
    void this.$router.push({ name: RouteNames.VenueEditTabAdditionalFeature, params: { venueId: this.venue.id } });
  }

  goToAreasStep () {
    void this.$router.push({ name: RouteNames.VenueEditTabAreas, params: { venueId: this.venue.id } });
  }

  goToOptionsStep () {
    void this.$router.push({ name: RouteNames.VenueEditTabOptions, params: { venueId: this.venue.id } });
  }
}
