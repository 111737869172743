













import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import VenuesData from '@/components/venues/venues-data.vue';
import { IVenue } from '@einfachgast/shared';
import { ValidationError } from 'class-validator';

@Component({
  name: 'VenueDataStep',
  components: {
    VenuesData,
  },
})
export default class VenueDataStep extends Vue {
  @Prop()
  venue: IVenue;

  @Prop({ default: (): ValidationError[] => [] })
  errors: ValidationError[];
}
